@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

.bootstrap-styles-widget {
  @import "bootstrap";
}

body {
  font-style: normal;
  font-weight: normal;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

menu {
  margin: 0;
  padding: 0;
}

// p {
//   margin: 0;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
  // margin: 0;
  font-weight: 700;
}

// ul {
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }

a,
button,
input,
label,
select,
textarea {
  outline: 0;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  background: none;
  padding: 0;
  margin: 0;
}

table {
  border-collapse: collapse;
}

/* @keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
} */

/* @keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
} */

.fadeInDown-enter {
  opacity: 0;
  /* transform: translate3d(0, -100%, 0); */
}
.fadeInDown-enter-active {
  opacity: 1;
  /* transform: translate3d(0, 0, 0); */
  transition: opacity 800ms, transform 800ms;
}
.fadeInDown-exit {
  opacity: 1;
  /* transform: translate3d(0, 0, 0); */
}
.fadeInDown-exit-active {
  opacity: 0;
  /* transform: translate3d(0, -100%, 0); */
  transition: opacity 800ms, transform 800ms;
}

.slideInDown-enter {
  transform: translate3d(0, -100%, 0);
  height: 0;
  visibility: visible;
}
.slideInDown-enter-active {
  transform: translate3d(0, 0, 0);
  height: 100%;
  transition: opacity 500ms, transform 500ms, height 500ms;
}
.slideInDown-exit {
  transform: translate3d(0, 0, 0);
  height: 100%;
}
.slideInDown-exit-active {
  transform: translate3d(0, -100%, 0);
  height: 0;
  visibility: visible;
  transition: opacity 500ms, transform 500ms, height 500ms;
}
