:root {
  --color-white: #fff;
  --color-black: #000;

  --color-gray-50: #f6f7fb;
  --color-gray-200: #f4f4f4;
  --color-gray-300: #cbc9c9;
  --color-gray-900: #010202;
  --color-dark-blue-500: #3e5ca7;
  --color-dark-blue-700: #55658d;
  --color-blue-100: #335eea;
  --color-blue: #0b5ed7;
  --color-dark-blue: #325fea;
  --color-disabled: #8f9eca;
  --color-red-200: #ea1f1f;
  --box-shadow-focus: 0 0 0.25rem rgba(49, 132, 253, 0.5);

  --primary: #335eea;
  --button: #335eea;
  --button-text: #ffffff;
  --button-border: #335eea;
  --search-button: #335eea;
  --search-button-text: #ffffff;
  --search-button-border: #335eea;
  --text: #000000;
  --badge-background: #edf5fb;
  --badge-link: #161d2d;
}
